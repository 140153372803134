import { ref } from 'vue';

/** Is retrying */
const retrying = ref(false);

/** Seconds before next call retry */
const secondsBeforeRetry = ref(5);

const interval = ref();

/** Is trying to re send a failed request  */
const hasFailed = ref(false);

/** last function called  */
const lastCall = ref();

function useCallRetry() {
  /**
   * Retry once to send a request
   *
   * @param {Function} callback Function to retry
   */
  async function retry(callback: Function, instantRetry?: boolean): Promise<void> {
    retrying.value = true;
    hasFailed.value = false;
    lastCall.value = callback;
    clearInterval(interval.value);

    const call = async () => {
      try {
        retrying.value = false;
        await callback();
        hasFailed.value = false;
      } catch (e) {
        hasFailed.value = true;
      }
    };

    if (instantRetry) {
      secondsBeforeRetry.value = 0;
      await call();
    } else {
      secondsBeforeRetry.value = 5;
      interval.value = setInterval(async () => {
        secondsBeforeRetry.value -= 1;
        if (secondsBeforeRetry.value === 0) {
          await call();
          retrying.value = false;
          clearInterval(interval.value);
          secondsBeforeRetry.value = 0;
        }
      }, 1000);
    }
  }

  function reset() {
    hasFailed.value = false;
  }

  return {
    retry, hasFailed, retrying, secondsBeforeRetry, lastCall, reset,
  };
}

export default useCallRetry;
