import useCallRetry from '../stores/callState';
import useFlashMessage from '../stores/flashMessage';

interface Options { contentType?: string, credentials?: RequestCredentials }

async function checkErrors(resp: Response, disableFlash?: boolean) {
  if (resp.ok) return resp;
  const flash = useFlashMessage();

  const errorMsg = `ERROR ${resp.status} (${resp.statusText})`;
  const serverText = await resp.text();

  let json = null;
  try {
    json = serverText ? JSON.parse(serverText) : null;
  } catch (e) {
    if (!disableFlash) flash.error('Une erreur interne est survenue, veuillez réessayer');
  }
  const result = {
    status: resp.status,
    error: errorMsg,
    description: json ? json['hydra:description'] : null,
  };

  if (disableFlash) throw new Error(result.description || errorMsg);

  if (result.status === 401) flash.error(result.description || 'Votre session a expiré, veuillez vous reconnecter.');
  else if (result.status === 403) flash.error(result.description || "Vous n'avez pas les droits pour effectuer cette action.");
  else if (result.status === 404) flash.error(result.description || "La ressource demandée n'existe pas.");
  else if (result.status === 502 || result.status === 520) flash.error(result.description || 'Le serveur est momentanément indisponible');
  else if (result.status === 422) flash.warning(result.description || result.error);
  else flash.error(result.description || 'Une erreur interne est survenue.');

  throw new Error(result.description || errorMsg);
}

async function toJSON(resp: Response) {
  const result = await resp.text();
  if (result) return JSON.parse(result);
  return '';
}

async function send<T = unknown>(
  url: string,
  method: string,
  data?: any,
  options?: Options,
  disableFlash?: boolean,
): Promise<T> {
  const init = {
    method,
    headers: {},
    credentials: options?.credentials || 'include',
  } as {
    method: string;
    headers: Record<string, string>;
    body?: string;
    credentials: RequestCredentials;
  };

  if (data) init.body = JSON.stringify(data);
  if (options?.contentType) init.headers['Content-Type'] = options?.contentType;
  const { reset } = useCallRetry();
  reset();

  const result = await fetch(url, init);

  await checkErrors(result, disableFlash);

  return toJSON(result);
}

export function postAsync<T = unknown>(url: string, data?: any, disableFlash?: boolean): Promise<T> {
  return send<T>(url, 'POST', data, { contentType: 'application/json' }, disableFlash);
}

export function putAsync<T = unknown>(url: string, data?: object, disableFlash?: boolean): Promise<T> {
  return send(url, 'PUT', data, { contentType: 'application/json' }, disableFlash);
}

export function patchAsync<T = unknown>(url: string, data?: object): Promise<T> {
  return send(url, 'PATCH', data, { contentType: 'application/merge-patch+json' });
}
export function getAsync<T = unknown>(url: string, options?: Options, disableFlash?: boolean): Promise<T> {
  return send(url, 'GET', undefined, options, disableFlash);
}

export async function getAllPages<T = unknown>(url: string, baseUrl: string): Promise<Array<T>> {
  interface Result {
    'hydra:totalItems':number
    'hydra:member': Array<T>;
    'hydra:view': { 'hydra:next'?: string };
  }
  const pageUrl: string | undefined = url;
  const query = await send<Result>(baseUrl + pageUrl, 'GET');
  const totalPagesItems = query['hydra:totalItems'];
  if (totalPagesItems <= 30) {
    return query['hydra:member'];
  }
  const queryFinale = await send<Result>(`${baseUrl + pageUrl}?itemsPerPage=${totalPagesItems}`, 'GET');
  return queryFinale['hydra:member'];
}

export function deleteAsync<T = unknown>(url: string): Promise<T> {
  return send(url, 'DELETE');
}

export function sleep(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}
